import { render, staticRenderFns } from "./contact-edit.vue?vue&type=template&id=7e9470ba&scoped=true"
import script from "./contact-edit.vue?vue&type=script&lang=js"
export * from "./contact-edit.vue?vue&type=script&lang=js"
import style0 from "./contact-edit.vue?vue&type=style&index=0&id=7e9470ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e9470ba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FileUploadInput: require('/codebuild/output/src2108204121/src/web/components/file-upload-input.vue').default,HintText: require('/codebuild/output/src2108204121/src/web/components/hint-text.vue').default,Suggestion: require('/codebuild/output/src2108204121/src/web/components/suggestion.vue').default})
