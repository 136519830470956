import { render, staticRenderFns } from "./contact-profile-card.vue?vue&type=template&id=73c728e1&scoped=true"
import script from "./contact-profile-card.vue?vue&type=script&lang=js"
export * from "./contact-profile-card.vue?vue&type=script&lang=js"
import style0 from "./contact-profile-card.vue?vue&type=style&index=0&id=73c728e1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73c728e1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FavStar: require('/codebuild/output/src2108204121/src/web/components/fav-star.vue').default,RedFlag: require('/codebuild/output/src2108204121/src/web/components/red-flag.vue').default,LoadingSpinner: require('/codebuild/output/src2108204121/src/web/components/loading-spinner.vue').default,GmapsAddress: require('/codebuild/output/src2108204121/src/web/components/gmaps-address.vue').default,ContactListModal: require('/codebuild/output/src2108204121/src/web/components/contact-list-modal.vue').default,HistoryModal: require('/codebuild/output/src2108204121/src/web/components/history-modal.vue').default})
