import { render, staticRenderFns } from "./contract-templates.vue?vue&type=template&id=9c6a22ac&scoped=true"
import script from "./contract-templates.vue?vue&type=script&lang=js"
export * from "./contract-templates.vue?vue&type=script&lang=js"
import style0 from "./contract-templates.vue?vue&type=style&index=0&id=9c6a22ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c6a22ac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src2108204121/src/web/components/loading-spinner.vue').default,PdfViewer: require('/codebuild/output/src2108204121/src/web/components/pdf-viewer.vue').default,SaveButton: require('/codebuild/output/src2108204121/src/web/components/save-button.vue').default})
