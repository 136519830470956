import { render, staticRenderFns } from "./contact-bulk-operation-modal.vue?vue&type=template&id=55f2764a&scoped=true"
import script from "./contact-bulk-operation-modal.vue?vue&type=script&lang=js"
export * from "./contact-bulk-operation-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55f2764a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src2108204121/src/web/components/loading-spinner.vue').default})
